<template>
    <el-card class="box-card-b" shadow="never">
        <el-empty description="404">
            <h1>这里什么都没有</h1>
        </el-empty>
    </el-card>

</template>

<script>
    export default {
        name: '404',
    }
</script>

<style scoped>
</style>